/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReactElement } from 'Type/Common.type';
import history from 'Util/History';
import { RootState } from 'Util/Store/Store.type';


import SizeGuide from './SizeGuide.component';

/** @namespace Route/SizeGuide/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): any => ({
});

/** @namespace Route/SizeGuide/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): any => ({
});

/** @namespace Route/SizeGuide/Container */
export class SizeGuideContainer extends PureComponent<any, any> {




  containerFunctions = {
  };




  containerProps(): Pick<any, any> {
    const { product } = this.props;


    return {
      product
    };
  }



  render(): ReactElement {
    return (
      <SizeGuide
        {...this.containerFunctions}
        {...this.containerProps()}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SizeGuideContainer);
