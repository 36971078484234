/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
// @ts-nocheck

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';
import React from "react"
import { ReactElement } from 'Type/Common.type';
import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';
import Image from 'Component/Image';
import SizeImage from "../../../public/sizeguide.jpg"
import CmsBlock from 'Component/CmsBlock';

import { SizeGuideComponentProps } from './SizeGuide.type';

import './SizeGuide.style';

/** @namespace Component/SizeGuide/Component */
export class SizeGuideComponent extends PureComponent<SizeGuideComponentProps> {
    static defaultProps: Partial<SizeGuideComponentProps> = {
        isPrimary: false,
    };


    renderHeading(): ReactElement {

        return (
            <tr>
                <th
                    block="KeyValueTable"
                    elem="Heading"

                >
                    {__("Size")}
                </th>
                <th
                    block="KeyValueTable"
                    elem="Heading"

                >
                    {__("Hip")}
                </th>
                <th
                    block="KeyValueTable"
                    elem="Heading"

                >
                    {__("Pants")}
                </th>
            </tr>
        );
    }


    renderMeasurementsTable() {

        return (
            <div block="KeyValueTable" elem="Wrapper">
                <table block="KeyValueTable">
                    <thead>
                        {this.renderHeading()}
                    </thead>
                    <tbody>
                        <tr><th>XXS</th><td>83-87</td><td>2</td></tr>
                        <tr><th>XS</th><td>88-92</td><td>4</td></tr>
                        <tr><th>Yes</th><td>93-97</td><td>6</td></tr>
                        <tr><th>M</th><td>98-102</td><td>8</td></tr>
                        <tr><th>l</th><td>103-107</td><td>10</td></tr>
                        <tr><th>XL</th><td>108-113</td><td>12</td></tr>
                        <tr><th>2XL</th><td>114-119</td><td>14</td></tr>
                        <tr><th>3XL</th><td>120-125</td><td>16</td></tr>
                        <tr><th>4XL</th><td>126-131</td><td>18</td></tr>
                        <tr><th>5XL</th><td>132-137</td><td>20</td></tr>


                    </tbody>
                </table>
            </div>
        )
    }

    renderContent(): ReactElement {

        return (
            <>
                {this.renderMeasurementsTable()}
            </>
        );

    }

    renderSteps(): ReactElement {
        return (
            <>
                <h2>{__("Know how to measure yourself")}</h2>
                <p>
                    {__("Because girdles are compression garments to redesign your figure, it is important that you follow our measurement process step by step.")}
                </p>

                <h3>{__("Step 1: Hip")}</h3>
                <p>
                    {__("Measure the contour of your hip by placing the measuring tape along the most prominent part.")}
                </p>
                <p block="sizeGuide" elem="measureImage">
                    <img
                        src={SizeImage}
                        alt="Step 1 size guide"
                        width="480"
                        height="259"
                    />
                </p>
            </>
        );


    }

    render(): ReactElement {

        const { product: {
            sizeguide
        } } = this.props;

        if (!sizeguide) {
            return null
        }


        return (
            <ContentWrapper
                label="Size Guide: Bottoms with Control"
                mix={{ block: 'ProductInformation' }}
                wrapperMix={{ block: 'ProductInformation', elem: 'Wrapper' }}

            >
                <CmsBlock identifier={sizeguide} deCode={true} />
            </ContentWrapper>
        )
    }
}

export default SizeGuideComponent;
